<!--
 * @Author: Akko
 * @Description: 学历/专业(专才)
-->
<template>
    <div v-if="!loading">
        <ZCInfoEducation ref="fourth" origin="client" :model.sync="fourth" @handleClick="handleClick" />

        <div class="operations-group">
            <el-button @click="goPre">上一步</el-button>
            <el-button type="primary" :loading="isSave" @click="submitForm('fourth',1)">保存</el-button>
            <el-button type="primary" @click="submitForm('fourth',2)">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import { expertFourth, expertDelEdu, expertDelQua } from "@/api/workfow.js";
import { mapActions } from "vuex";
export default {
    name: "Qualification",
    data() {
        return {
            loading: true,
            isSave: false, //防抖loading
            fourth: {},
        };
    },
    created() {
        this.dataEcho();
    },
    methods: {
        ...mapActions(["expertData"]),
        // 数据回显
        dataEcho() {
            this.expertData(this.$route.params.id).then((res) => {
                this.loading = false;
                if (res.data.fourth.question === undefined) {
                    this.fourth = {};
                } else {
                    this.fourth = res.data.fourth;
                }
            });
        },
        // 内部操作事件
        handleClick(event, data) {
            console.log(event, data);
            if (event === "delete") {
                // 删除学历
                if (data.id === undefined) {
                    this.$refs["fourth"].model.education.splice(data.index, 1);
                } else {
                    expertDelEdu(data.id).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.data);
                            this.$refs["fourth"].model.education.splice(
                                data.index,
                                1
                            );
                        }
                    });
                }
            } else if (event === "qualiDel") {
                // 删除资格
                if (data.id === undefined) {
                    this.$refs["fourth"].model.qualification.splice(
                        data.index,
                        1
                    );
                } else {
                    expertDelQua(data.id).then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.data);
                            this.$refs["fourth"].model.qualification.splice(
                                data.index,
                                1
                            );
                        }
                    });
                }
            }
        },
        /**
         * 点击事件
         * 表单名字 formName
         * 操作 operation 1 保存 2 下一步
         */
        submitForm(formName, operation) {
            if (operation === 1) {
                // 保存
                this.isSave = true;
                this.hanledSaveQuestion(formName, "save");
            } else {
                // 下一步
                this.$nextTick(() => {
                    const { validateForm } = this.$refs[formName];
                    validateForm()
                        .then((res) => {
                            console.log(res);
                            this.hanledSaveQuestion(formName, "next");
                        })
                        .catch((err) => {
                            console.log(err, "err");
                            return this.$message.error("请完善信息");
                        });
                });
            }
        },
        // 发起保存动作
        hanledSaveQuestion(formName, txt) {
            let obj = Object.assign(
                {
                    order_id: this.$route.params.id,
                    step: txt === "save" ? 0 : 4,
                },
                this.$refs[formName].model
            );
            expertFourth(obj).then((res) => {
                this.isSave = false;
                if (res.code == 200) {
                    // 切换步骤保存 下一步的时候才设置
                    if (txt === "save") {
                        this.dataEcho();
                        this.$message.success("保存成功！");
                    } else {
                        this.$message.success("提交成功！");
                        this.$router.replace({
                            name: "etep5",
                        });
                    }
                }
            });
        },
        // 上一步
        goPre() {
            this.$router.replace({
                name: "etep3",
            });
        },
    },
};
</script>
